<template>
  <div
    :id="componentId"
    :class="`right-panel ${activeTab.tab === 'DISTRIBUTION' ? 'distribution-view-container' : ''}`"
  >
    <div class="right-active-tab-cont">
      <b-select
        :options="menuItems"
        :selected="activeTab"
        :show-tool-tip="false"
        :selected-text-icon="activeTab.icon"
        @change="updateActiveMenu"
      />
    </div>
    <daily-view-new-chart v-if="activeTab.tab === 'DAILY VIEW'" class="chart-sec" />
    <distribution-chart v-else-if="activeTab.tab === 'DISTRIBUTION'" class="chart-sec" />
    <div v-else-if="activeTab.tab === 'INCREMENTALITY'" class="incrementality-tab">
      <incrementality class="chart-sec" />
    </div>
    <div v-else-if="activeTab.tab === 'HALO EFFECT'" class="halo-effect-tab">
      <halo-effect class="chart-sec" />
    </div>
    <brand-lift v-else-if="activeTab.tab === 'BRAND LIFT'" class="chart-sec" />
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import { sanitizeTitle } from '@/util/utility-functions';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import * as util from '~/util/utility-functions';
import authzMixin from '~/components/mixins/authz-mixin';
import {
  WINDOW_TYPE_DAILY_VIEW,
  WINDOW_TYPE_DISTRIBUTION,
  WINDOW_TYPE_INCREMENTALITY,
  WINDOW_TYPE_HALO_EFFECT,
  WINDOW_TYPE_BRAND_LIFT,
} from '~/constant';
import config from '~/config';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';

export default {
  name: 'DashGraphsPanel',
  components: {
    DailyViewNewChart: () =>
      import(
        /* webpackChunkName: "daily-view-new-chart" */ '~/components/dash-panels/graphs/daily-view-new.vue'
      ),
    DistributionChart: () =>
      import(
        /* webpackChunkName: "distribution-chart" */ '~/components/dash-panels/graphs/distribution.vue'
      ),
    Incrementality: () =>
      import(
        /* webpackChunkName: "incrementality" */ '~/components/dash-panels/graphs/incrementality.vue'
      ),
    HaloEffect: () =>
      import(
        /* webpackChunkName: "halo-effect" */ '~/components/dash-panels/graphs/halo-effect.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    BrandLift: () =>
      import(/* webpackChunkName: "brand-lift" */ '~/components/dash-panels/graphs/brand-lift.vue'),
  },
  mixins: [authzMixin, gaEventsMixin],
  data() {
    return {
      activeTab: { tab: 'DAILY VIEW', displayText: 'Daily View', icon: 'chart-line' },
      hasBLCampaigns: false,
      menuItems: [],
      allTabs: [
        {
          name: 'Daily View',
          to: '',
          windowType: WINDOW_TYPE_DAILY_VIEW,
          icon: 'chart-line',
        },
        {
          name: 'Distribution',
          to: '',
          windowType: WINDOW_TYPE_DISTRIBUTION,
          icon: 'chart-pie-alt',
        },
        {
          name: 'Incrementality',
          to: '',
          windowType: WINDOW_TYPE_INCREMENTALITY,
          icon: 'chart-bar',
        },
        {
          name: 'Halo Effect',
          to: '',
          windowType: WINDOW_TYPE_HALO_EFFECT,
          icon: 'chart-network',
        },
        {
          name: 'Brand Lift',
          to: '',
          windowType: WINDOW_TYPE_BRAND_LIFT,
          icon: 'chart-bar',
        },
      ],
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    ioOptions: get('dashboard/filters@ioOptions'),
    selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),

    isShowBLTab() {
      return this.ioOptions && this.ioOptions.some((io) => io.brandLiftEnabled);
    },
    isShowIncrementalityTab() {
      return this.ioOptions && this.ioOptions.some((io) => io.incrementalityEnabled);
    },
    isShowHaloEffectTab() {
      return this.isShowIncrementalityTab;
    },
    payload() {
      const selectCampaigns = this.ioOptions.filter((o) => o.checked && o.campaignId);
      const campaignIds = selectCampaigns.map((o) => o.campaignId);
      const campaignId = campaignIds && campaignIds.length > 0 ? campaignIds[0] : 0;

      const result = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        io: this.selectedIOs,
        intCampaignId: campaignId,
        adGroups: this.selectedAdGrpOptStr,
      };
      return result;
    },
    componentId() {
      return `graphs-${sanitizeTitle(this.activeTab.tab)?.toLowerCase()}`;
    },
  },
  watch: {
    async selectedIOs() {
      try {
        await this.checkAdvertiserHasBLCampaigns(this.payload);
        this.updateMenuItems();
      } catch (err) {
        console.error('error fetching lift types', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
  },
  async mounted() {
    try {
      await this.checkAdvertiserHasBLCampaigns(this.payload);
      this.updateMenuItems();
    } catch (err) {
      console.error('error mounting graph panel', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    hasAdminAccess() {
      return this.isInternalAdmin;
    },
    validateParams(payload, advertiser) {
      if (!advertiser || isBlank(advertiser.id)) {
        return false;
      }
      if (!payload.io.length) {
        return false;
      }
      const keys = ['client', 'advertiser'];
      return !util.isAnyBlank(payload, keys);
    },
    updateActiveMenu(tabObj = {}) {
      const { name, icon } = tabObj;
      const nameUpperCase = name.toUpperCase();

      if (this.activeTab.tab !== nameUpperCase) {
        this.activeTab = { tab: nameUpperCase, displayText: name, icon };
        // eBus.$emit('active-tab-graph-panel', tab);
        if (config.gaEnabled()) {
          const gaEv = {
            tab_name: nameUpperCase,
          };
          this.fireGAEvent('flp_grph_pnl_tab_chg', gaEv, false);
        }
      }
    },
    async checkAdvertiserHasBLCampaigns(payload) {
      payload.io = payload.io || [];
      if (!this.validateParams(payload, this.advertiser)) {
        this.hasBLCampaigns = false;
        return;
      }
      // call api to fetch lift types
      try {
        const res = await advertiserReportsAPI.brandLiftTypes(
          this.advertiser.id,
          buildQueryString(payload)
        );
        this.hasBLCampaigns = res && res.length > 0;
      } catch (err) {
        this.hasBLCampaigns = false;
        console.error('error in checking if advertiser has BL campaigns ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    updateMenuItems() {
      const incTabIndex = this.allTabs.map((t) => t.windowType).indexOf(WINDOW_TYPE_INCREMENTALITY);
      this.allTabs[incTabIndex].hidden = !this.isShowIncrementalityTab;
      if (this.selectedEvent?.isFalseEvent) {
        this.allTabs[incTabIndex].hidden = true;
      }

      const haloTabIndex = this.allTabs.map((t) => t.windowType).indexOf(WINDOW_TYPE_HALO_EFFECT);
      this.allTabs[haloTabIndex].hidden = !this.isShowHaloEffectTab;
      if (this.selectedEvent?.isFalseEvent) {
        this.allTabs[haloTabIndex].hidden = true;
      }

      const blTabIndex = this.allTabs.map((t) => t.windowType).indexOf(WINDOW_TYPE_BRAND_LIFT);
      this.allTabs[blTabIndex].hidden = !this.isShowBLTab;

      if (this.allTabs[blTabIndex].hidden && this.activeTab?.tab.toUpperCase() === 'BRAND LIFT') {
        this.activeTab = { tab: 'DAILY VIEW', displayText: 'Daily View', icon: 'chart-line' };
      }

      if (
        this.allTabs[incTabIndex].hidden &&
        this.activeTab?.tab.toUpperCase() === 'INCREMENTALITY'
      ) {
        this.activeTab = { tab: 'DAILY VIEW', displayText: 'Daily View', icon: 'chart-line' };
      }

      if (
        this.allTabs[haloTabIndex].hidden &&
        this.activeTab?.tab.toUpperCase() === 'HALO EFFECT'
      ) {
        this.activeTab = { tab: 'DAILY VIEW', displayText: 'Daily View', icon: 'chart-line' };
      }

      this.menuItems = this.allTabs.filter((tab) => !tab.hidden);
    },
  },
};
</script>
<style lang="scss" scroped>
.right-active-tab-cont {
  position: absolute;
  top: -39px;
  left: 0px;
}
.right-active-tab-cont .dropdown .select {
  color: #fff;
  background-color: #212429 !important;
  border: unset !important;
  border-radius: unset !important;
  border-top: 2px solid var(--primarycolor) !important;
  width: 220px;
}
.right-active-tab-cont .dropdown .select.open {
  background-color: #383a44 !important;
}
.right-active-tab-cont .dropdown .dropdown-menu {
  width: 220px !important;
}
.right-active-tab-cont .dropdown .selected-opt-wrapper {
  display: inline-flex;
}
.right-active-tab-cont .dropdown .selected-opt-wrapper .selected-icon {
  margin-top: 3px;
  margin-right: 5px;
  width: 15px;
  color: #cad1d6;
}
.right-active-tab-cont .dropdown .dropdown-menu .norm-option {
  display: flex;
}
.right-active-tab-cont .dropdown .dropdown-menu .norm-option .icon {
  margin-top: 1px;
  margin-right: 5px;
  margin-left: -5px;
  width: 13px;
}
.right-active-tab-cont .dropdown .dropdown-menu .norm-option.Incrementality svg {
  transform: rotate(90deg) scaleX(-1);
}
.right-active-tab-cont .dropdown .select.Incrementality .selected-opt-wrapper svg {
  transform: rotate(90deg) scaleX(-1);
}
</style>

<style lang="scss">
.chart-sec {
  height: 100%;
}
.right-panel.distribution-view-container {
  .chart-sec {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.incrementality-tab .chart-tab .c3 .c3-axis-x .tick:last-of-type text,
.halo-effect-tab .chart-tab .c3 .c3-axis-x .tick:last-of-type text {
  transform: unset !important;
}
.nav-tabs {
  position: absolute;
  top: -5.5%;
  left: 0;
}
.right-panel .nav-tabs .tab-active a {
  border-top: 1px solid var(--primarycolor) !important;
}
.right-panel.distribution-view-container {
  padding-top: 40px;
  padding-left: 47px;
  padding-right: 60px;
}
</style>
